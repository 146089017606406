// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function cropImage(element, param) {
  var object = param.object;
  var key = element.line.key;
  var settings = fields[object][key];
  if (settings.reference != 'document') return;
  var row = data[object][param.id];

  var popup = buildPopup({
    title: 'Avatar ausschneiden',
    buttons: {
      'Ausschnitt benutzen': function () {
        if (popup.croppie)
          popup.croppie
            .croppie('result', {
              type: 'base64',
              size: {width: 120, height: 120},
              format: 'png',
              circle: false,
            })
            .then(function (avatar) {
              ajax({
                object: object,
                id: row.id,
                post: true,
                send: {avatar: avatar},
              });
              $('.avatar').attr('src', avatar);
            });
        popup.close();
      },
    },
  });

  var url =
    endpoint +
    'document,' +
    row[key] +
    '?window=' +
    windowId +
    '&func=content&v=' +
    rand();
  popup.croppie = $('<div></div>')
    .appendTo(popup.body)
    .croppie({
      url: url,
      viewport: {width: 458, height: 458, type: 'circle'},
      boundary: {width: 458, height: 458},
      enableResize: true,
      enforceBoundary: false,
      enableExif: true,
    });
}
